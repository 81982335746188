var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t();
}(window, function () {
  return function (e) {
    var t = {};

    function n(r) {
      if (t[r]) return t[r].exports;
      var o = t[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return e[r].call(o.exports, o, o.exports, n), o.l = !0, o.exports;
    }

    return n.m = e, n.c = t, n.d = function (e, t, r) {
      n.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: r
      });
    }, n.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, n.t = function (e, t) {
      if (1 & t && (e = n(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var r = Object.create(null);
      if (n.r(r), Object.defineProperty(r, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var o in e) n.d(r, o, function (t) {
        return e[t];
      }.bind(null, o));
      return r;
    }, n.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return n.d(t, "a", t), t;
    }, n.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, n.p = "/", n(n.s = 4);
  }([function (e, t, n) {
    var r = n(1),
        o = n(2);
    "string" == typeof (o = o.__esModule ? o.default : o) && (o = [[e.i, o, ""]]);
    var i = {
      insert: "head",
      singleton: !1
    };
    r(o, i);
    e.exports = o.locals || {};
  }, function (e, t, n) {
    "use strict";

    var r,
        o = function () {
      return void 0 === r && (r = Boolean(window && document && document.all && !window.atob)), r;
    },
        i = function () {
      var e = {};
      return function (t) {
        if (void 0 === e[t]) {
          var n = document.querySelector(t);
          if (window.HTMLIFrameElement && n instanceof window.HTMLIFrameElement) try {
            n = n.contentDocument.head;
          } catch (e) {
            n = null;
          }
          e[t] = n;
        }

        return e[t];
      };
    }(),
        a = [];

    function u(e) {
      for (var t = -1, n = 0; n < a.length; n++) if (a[n].identifier === e) {
        t = n;
        break;
      }

      return t;
    }

    function c(e, t) {
      for (var n = {}, r = [], o = 0; o < e.length; o++) {
        var i = e[o],
            c = t.base ? i[0] + t.base : i[0],
            s = n[c] || 0,
            l = "".concat(c, " ").concat(s);
        n[c] = s + 1;
        var f = u(l),
            d = {
          css: i[1],
          media: i[2],
          sourceMap: i[3]
        };
        -1 !== f ? (a[f].references++, a[f].updater(d)) : a.push({
          identifier: l,
          updater: b(d, t),
          references: 1
        }), r.push(l);
      }

      return r;
    }

    function s(e) {
      var t = document.createElement("style"),
          r = e.attributes || {};

      if (void 0 === r.nonce) {
        var o = n.nc;
        o && (r.nonce = o);
      }

      if (Object.keys(r).forEach(function (e) {
        t.setAttribute(e, r[e]);
      }), "function" == typeof e.insert) e.insert(t);else {
        var a = i(e.insert || "head");
        if (!a) throw new Error("Couldn't find a style target. This probably means that the value for the 'insert' parameter is invalid.");
        a.appendChild(t);
      }
      return t;
    }

    var l,
        f = (l = [], function (e, t) {
      return l[e] = t, l.filter(Boolean).join("\n");
    });

    function d(e, t, n, r) {
      var o = n ? "" : r.media ? "@media ".concat(r.media, " {").concat(r.css, "}") : r.css;
      if (e.styleSheet) e.styleSheet.cssText = f(t, o);else {
        var i = document.createTextNode(o),
            a = e.childNodes;
        a[t] && e.removeChild(a[t]), a.length ? e.insertBefore(i, a[t]) : e.appendChild(i);
      }
    }

    function p(e, t, n) {
      var r = n.css,
          o = n.media,
          i = n.sourceMap;
      if (o ? e.setAttribute("media", o) : e.removeAttribute("media"), i && "undefined" != typeof btoa && (r += "\n/*# sourceMappingURL=data:application/json;base64,".concat(btoa(unescape(encodeURIComponent(JSON.stringify(i)))), " */")), e.styleSheet) e.styleSheet.cssText = r;else {
        for (; e.firstChild;) e.removeChild(e.firstChild);

        e.appendChild(document.createTextNode(r));
      }
    }

    var v = null,
        h = 0;

    function b(e, t) {
      var n, r, o;

      if (t.singleton) {
        var i = h++;
        n = v || (v = s(t)), r = d.bind(null, n, i, !1), o = d.bind(null, n, i, !0);
      } else n = s(t), r = p.bind(null, n, t), o = function () {
        !function (e) {
          if (null === e.parentNode) return !1;
          e.parentNode.removeChild(e);
        }(n);
      };

      return r(e), function (t) {
        if (t) {
          if (t.css === e.css && t.media === e.media && t.sourceMap === e.sourceMap) return;
          r(e = t);
        } else o();
      };
    }

    e.exports = function (e, t) {
      (t = t || {}).singleton || "boolean" == typeof t.singleton || (t.singleton = o());
      var n = c(e = e || [], t);
      return function (e) {
        if (e = e || [], "[object Array]" === Object.prototype.toString.call(e)) {
          for (var r = 0; r < n.length; r++) {
            var o = u(n[r]);
            a[o].references--;
          }

          for (var i = c(e, t), s = 0; s < n.length; s++) {
            var l = u(n[s]);
            0 === a[l].references && (a[l].updater(), a.splice(l, 1));
          }

          n = i;
        }
      };
    };
  }, function (e, t, n) {
    (t = n(3)(!1)).push([e.i, ".cdx-underline {\n    text-decoration: underline;\n}\n", ""]), e.exports = t;
  }, function (e, t, n) {
    "use strict";

    e.exports = function (e) {
      var t = [];
      return t.toString = function () {
        return this.map(function (t) {
          var n = function (e, t) {
            var n = e[1] || "",
                r = e[3];
            if (!r) return n;

            if (t && "function" == typeof btoa) {
              var o = (a = r, u = btoa(unescape(encodeURIComponent(JSON.stringify(a)))), c = "sourceMappingURL=data:application/json;charset=utf-8;base64,".concat(u), "/*# ".concat(c, " */")),
                  i = r.sources.map(function (e) {
                return "/*# sourceURL=".concat(r.sourceRoot || "").concat(e, " */");
              });
              return [n].concat(i).concat([o]).join("\n");
            }

            var a, u, c;
            return [n].join("\n");
          }(t, e);

          return t[2] ? "@media ".concat(t[2], " {").concat(n, "}") : n;
        }).join("");
      }, t.i = function (e, n, r) {
        "string" == typeof e && (e = [[null, e, ""]]);
        var o = {};
        if (r) for (var i = 0; i < (this || _global).length; i++) {
          var a = (this || _global)[i][0];
          null != a && (o[a] = !0);
        }

        for (var u = 0; u < e.length; u++) {
          var c = [].concat(e[u]);
          r && o[c[0]] || (n && (c[2] ? c[2] = "".concat(n, " and ").concat(c[2]) : c[2] = n), t.push(c));
        }
      }, t;
    };
  }, function (e, t, n) {
    "use strict";

    n.r(t), n.d(t, "default", function () {
      return i;
    });
    n(0);

    function r(e) {
      return (r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      })(e);
    }

    function o(e, t) {
      for (var n = 0; n < t.length; n++) {
        var o = t[n];
        o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, (i = o.key, a = void 0, a = function (e, t) {
          if ("object" !== r(e) || null === e) return e;
          var n = e[Symbol.toPrimitive];

          if (void 0 !== n) {
            var o = n.call(e, t || "default");
            if ("object" !== r(o)) return o;
            throw new TypeError("@@toPrimitive must return a primitive value.");
          }

          return ("string" === t ? String : Number)(e);
        }(i, "string"), "symbol" === r(a) ? a : String(a)), o);
      }

      var i, a;
    }

    var i = function () {
      function e(t) {
        var n = t.api;
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, e), (this || _global).api = n, (this || _global).button = null, (this || _global).tag = "U", (this || _global).iconClasses = {
          base: (this || _global).api.styles.inlineToolButton,
          active: (this || _global).api.styles.inlineToolButtonActive
        };
      }

      var t, n, r;
      return t = e, r = [{
        key: "CSS",
        get: function () {
          return "cdx-underline";
        }
      }, {
        key: "isInline",
        get: function () {
          return !0;
        }
      }, {
        key: "sanitize",
        get: function () {
          return {
            u: {
              class: e.CSS
            }
          };
        }
      }], (n = [{
        key: "render",
        value: function () {
          return (this || _global).button = document.createElement("button"), (this || _global).button.type = "button", (this || _global).button.classList.add((this || _global).iconClasses.base), (this || _global).button.innerHTML = (this || _global).toolboxIcon, (this || _global).button;
        }
      }, {
        key: "surround",
        value: function (t) {
          if (t) {
            var n = (this || _global).api.selection.findParentTag((this || _global).tag, e.CSS);

            n ? this.unwrap(n) : this.wrap(t);
          }
        }
      }, {
        key: "wrap",
        value: function (t) {
          var n = document.createElement((this || _global).tag);
          n.classList.add(e.CSS), n.appendChild(t.extractContents()), t.insertNode(n), (this || _global).api.selection.expandToTag(n);
        }
      }, {
        key: "unwrap",
        value: function (e) {
          (this || _global).api.selection.expandToTag(e);

          var t = window.getSelection(),
              n = t.getRangeAt(0),
              r = n.extractContents();
          e.parentNode.removeChild(e), n.insertNode(r), t.removeAllRanges(), t.addRange(n);
        }
      }, {
        key: "checkState",
        value: function () {
          var t = (this || _global).api.selection.findParentTag((this || _global).tag, e.CSS);

          (this || _global).button.classList.toggle((this || _global).iconClasses.active, !!t);
        }
      }, {
        key: "toolboxIcon",
        get: function () {
          return "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\" viewBox=\"0 0 24 24\"><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M9 7.5V11.5C9 12.2956 9.31607 13.0587 9.87868 13.6213C10.4413 14.1839 11.2044 14.5 12 14.5C12.7956 14.5 13.5587 14.1839 14.1213 13.6213C14.6839 13.0587 15 12.2956 15 11.5V7.5\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M7.71429 18H16.2857\"/></svg>";
        }
      }]) && o(t.prototype, n), r && o(t, r), Object.defineProperty(t, "prototype", {
        writable: !1
      }), e;
    }();
  }]).default;
});
export default exports;
export const Underline = exports.Underline;